@use '../colors.scss';

// TODO: all of those @ statements should in inside of mixns 

@font-face {
    font-family: 'Material Design Icons';
    src: url("../../public/material_designs_icons.woff2") format("woff2");
} 

footer{
    background-color: #1E293C;
    text-align: center;
    padding: 8px;
    color: white;
    position: absolute;    
    font-size: 13px;
    width:100%;
    position:fixed;
    bottom:0px;
}