@import './_colors.scss';
@import "./components/header.scss";
@import "./components/footer.scss";
@import "./views/home/home.scss";

@font-face {
    font-family: 'Rubik,sans-serif';
    src: url("../public/font.woff2") format("woff2");
} 

html body{
    font-family: 'Rubik,sans-serif';
    background-color: #263874;
}

p {
    line-height: 2;
    margin-bottom: 0!important;
    margin-top: 1.5rem!important;    
    color: #FFFFFFCC;
    font-size: 15px;
}

.websiteBody{
    top: 94px;
    position: relative;
}

.contentSection{
    padding-top: 30px;
}

.image-space{
    padding-bottom: 80px;
    opacity: .6;
}

.sticky{
    position: fixed;
    width: 100%;
    z-index: 999;
    box-shadow: 2px 0px 2px gray;
    background-color: white;
}

.button:focus:not(:focus-visible){
    outline: 0;
}

.actionLink{
    text-decoration: none;
    color: white;
}

.actionLink:hover{
    color: white;
}

.hover-underline-animation-blue {
    display: inline-block;
    position: relative;
    color: white;
  }
  
.hover-underline-animation-blue::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #0087ca;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}

.hover-underline-animation-blue:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

.hover-underline-animation-black {
    display: inline-block;
    position: relative;
  }
  
.hover-underline-animation-black::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: var(--bs-nav-link-hover-color);
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}

.hover-underline-animation-black:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

.button{
    background: #CC1A36;
    border-color: transparent !important;
    color: #fff;
    z-index: 10;
    position: absolute;
    border: 1px solid;
    border-radius: 4px;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 1px;
    padding: 14px 36px;
    text-transform: uppercase;
    transition: all .5s;
    margin-right: 1.5rem!important;
    text-decoration: none;
}

.button:active, .button:focus, .button:hover, .open{
    box-shadow: 0 3px 20px -8px #000;
    color: #fff;
    outline: none;
    text-decoration: none;
    -webkit-transform: translateY(-3px);
    transform: translateY(-3px);
}


.fadeIn .contentSection .container{
    animation: 0.5s fadeIn forwards;
}
  
.fadeOut .contentSection .container{
    animation: 0.5s fadeOut forwards;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translate(-20px, 0);
    }
    to {
        opacity: 1;
        transform: translate(0px, 0px);
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
        transform: translate(0px, 0px);
    }
    to {
        transform: translate(-20px, 0);
        opacity: 0;
    }
}