@use '../colors.scss';

// TODO: all of those @ statements should in inside of mixns 

@font-face {
    font-family: 'Material Design Icons';
    src: url("../../public/material_designs_icons.woff2") format("woff2");
} 

@keyframes expandAnimation {
    from {height: 1px;}
    to {height: 150px; }
}

@keyframes collapseAnimation {
    from {height: 150px;}
    to {height: 0px; }
}

@keyframes fadeInAnimation {
    0% {opacity: 0;}
    100% {opacity: 1; }
}

.menu-button{
    margin: 10px;
}

.header{
    background-color: white;
    padding: 15px;
}

.logo{
    width: 100px;
}

.menuContainerOpen{
    display: block !important;
    animation-name: expandAnimation;
    animation-duration: .5s;
}

.menuContainerClose{
    display: block !important;
    animation-name: collapseAnimation;
    animation-duration: .5s;
    animation-fill-mode: forwards;
}

.menuContentOpen{
    animation-name: fadeInAnimation;
    animation-duration: 1s;
}

.mdi-set, .mdi:before{
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: inline-block;
    font-family: 'Material Design Icons';
    font: normal normal normal 24px/1;
    font-size: inherit;
    line-height: inherit;
}

.mdi-menu:before{
    content: "\F035C";
}

.navbar-toggler{
    border:0;
}

.navbar-toggler:focus {
    box-shadow: none;
}

.collapse:not(.show){
    display: none;
}

.desktopNavBar{
    display: none !important;
    
}

@media (min-width: 992px){
    .navbar-expand-lg .navbar-toggler, .navbar-expand-lg .offcanvas-header{
        display: none !important;
    }

    .desktopNavBar{
        display: block !important;
    }

    .mobileNavBar{
        visibility: hidden;
    }
}


