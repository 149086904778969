@keyframes bounceAnimation {
    from {top: 0px;}
    to {top: -20px; }
}

.homeSection{
    background-color: #263874;
    background-image: url('~/public/images/Home_background.png');
    background-position: 50%;
    background-size: cover;
    height: 100vh;
    position: relative;
    background-repeat: repeat-y;
}

.bounceImage{
    animation-name: bounceAnimation;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-duration: 1s;    
    padding-bottom: 50px;
}

@media only screen and (max-width: 900px){
    .bounceImage {
        padding-top: 50px;
    }
}